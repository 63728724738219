<template>
  <header class="navigation" ref="navigation">
    <nav class="navigation-menu">
      <div class="navigation-menu-box">
        <router-link
          :to="{
            name: 'LandingDashboard',
          }"
          class="navigation-menu-box-logo">
          <img
            v-if="
              window.userData &&
              window.userData.tenant &&
              window.userData.tenant.logo &&
              window.userData.tenant.logo?.file_data
            "
            :src="window.userData.tenant.logo.file_data.toString()" />
          <img
            v-else-if="
              window.userData &&
              window.userData.tenant &&
              window.userData.tenant.icon &&
              window.userData.tenant.icon?.file_data
            "
            :src="window.userData.tenant.icon.file_data.toString()" />
          <img src="@/assets/logo.svg" v-else />
        </router-link>
        <div class="navigation-component-box-links">
          <router-link
            :to="{
              name: 'EmployeesView',
            }"
            :class="{
              'active-navigation-list-item': includesName($route.name, 'EmployeesView'),
            }"
            class="list-text-bold disabled-text">
            Employees
          </router-link>
        </div>
      </div>
      <div
        class="hamburger"
        @click="navigationOpened = true"
        :class="{
          'hamburger-menu-opened': navigationOpened,
        }">
        <div class="block"></div>
        <div class="block"></div>
        <div class="block"></div>
      </div>
      <Transition name="navigation-component-hamburger-list-animation">
        <div
          v-if="navigationOpened"
          class="navigation-component-hamburger-list"
          v-click-outside="
            () => {
              navigationOpened = false;
            }
          ">
          <div class="navigation-component-hamburger-list-options">
            <router-link
              v-if="window.userData && window.userData.has_multiple_tenants"
              @click="navigationOpened = false"
              :to="{
                name: 'ClientSelection',
              }"
              :class="{
                'active-navigation-list-item': includesName($route.name, 'ClientSelection'),
              }"
              class="navigation-component-hamburger-list-options-option list-text-bold disabled-text">
              Client Selection
            </router-link>

            <router-link
              @click="navigationOpened = false"
              :to="{
                name: 'EmployeesView',
              }"
              :class="{
                'active-navigation-list-item': includesName($route.name, 'EmployeesView'),
              }"
              class="navigation-component-hamburger-list-options-option navigation-component-hamburger-list-options-option-desktop list-text-bold disabled-text">
              Employees
            </router-link>

            <router-link
              @click="navigationOpened = false"
              :to="{
                name: 'UsersView',
              }"
              v-if="window.userData && window.userData.scopes.includes('tenant-management')"
              :class="{
                'active-navigation-list-item':
                  includesName($route.name, 'User') && !includesName($route.name, 'Global'),
              }"
              class="navigation-component-hamburger-list-options-option list-text-bold disabled-text">
              Users
            </router-link>

            <div
              class="navigation-component-hamburger-list-options-option-column"
              v-if="window.userData && window.userData.role === 'system-administrator'">
              <div
                class="navigation-component-hamburger-list-options-option-column-row"
                @click="handleDropdown('system-administrator')">
                System Administrator
                <div
                  class="arrow"
                  :class="{
                    'top-arrow': dropdownExpanded.includes('system-administrator'),
                    'bottom-arrow': !dropdownExpanded.includes('system-administrator'),
                  }"></div>
              </div>
              <div
                class="navigation-component-hamburger-list-options-option-column-data"
                :class="{
                  'navigation-component-hamburger-list-options-option-column-data-expanded':
                    dropdownExpanded.includes('system-administrator'),
                }">
                <router-link
                  @click="navigationOpened = false"
                  :to="{
                    name: 'ClientsView',
                  }"
                  :class="{
                    'active-navigation-list-item': includesName($route.name, 'Clients'),
                  }"
                  class="navigation-component-hamburger-list-options-option list-text-bold disabled-text">
                  Clients
                </router-link>
                <router-link
                  @click="navigationOpened = false"
                  :to="{
                    name: 'UsersViewGlobal',
                  }"
                  :class="{
                    'active-navigation-list-item': includesName($route.name, 'Global'),
                  }"
                  class="navigation-component-hamburger-list-options-option list-text-bold disabled-text">
                  Users Global
                </router-link>
                <router-link
                  @click="navigationOpened = false"
                  :to="{
                    name: 'ScopesManagement',
                  }"
                  :class="{
                    'active-navigation-list-item': includesName($route.name, 'ScopesManagement'),
                  }"
                  class="navigation-component-hamburger-list-options-option list-text-bold disabled-text">
                  Scopes
                </router-link>
                <router-link
                  @click="navigationOpened = false"
                  :to="{
                    name: 'PrivilegesConfiguration',
                  }"
                  :class="{
                    'active-navigation-list-item': includesName($route.name, 'PrivilegesConfiguration'),
                  }"
                  class="navigation-component-hamburger-list-options-option list-text-bold disabled-text">
                  Privileges Configuration
                </router-link>

                <router-link
                  @click="navigationOpened = false"
                  :to="{
                    name: 'LogsView',
                  }"
                  class="navigation-component-hamburger-list-options-option list-text-bold disabled-text">
                  Logs
                </router-link>
              </div>
            </div>

            <div
              class="navigation-component-hamburger-list-options-option navigation-component-hamburger-list-options-option-logout list-text-bold disabled-text"
              @click="logOutUser">
              Log out
              <div v-html="icons.logoutIcon"></div>
            </div>
            <div class="navigation-component-hamburger-list-options-option-footer">
              <p class="info-text center-text">© {{ new Date().getFullYear() }} Vortec | All rights reserved</p>
              <p class="info-text center-text">
                Developed by <a href="https://vortec.io" class="primary-text info-text">Vortec</a>
              </p>
            </div>
          </div>
        </div>
      </Transition>
    </nav>
    <div id="sub-navigation-box" v-if="$slots['sub-navigation']">
      <slot name="sub-navigation"></slot>
    </div>
  </header>
</template>

<script setup lang="ts">
import axios from 'axios';
import router from '@/router';
import { RouteRecordName } from 'vue-router';
import { ref, onMounted, onBeforeUnmount, useTemplateRef } from 'vue';
import { useDebounceFunc } from 'vortec-components-front';
import icons from '@/icons';

const navigationOpened = ref(false);
const navigation = useTemplateRef<HTMLDivElement>('navigation');

function includesName(outter: RouteRecordName | null | undefined, inner: string) {
  if (!outter) return false;
  const outterString = outter.toString();
  return outterString.includes(inner);
}

function logOutUser() {
  axios.post('/api/logout').then(() => {
    window.userData = null;
    router.push({ name: 'LoginPage' });
  });
}

const previousScrollY = ref(0);

let navigationElement: HTMLElement | null = null;
let tableRowElement: HTMLElement | null = null;
let table: HTMLElement | null = null;

const cacheElements = () => {
  navigationElement = navigation.value;
  tableRowElement = document.querySelector('.table-row-header-nav');
  table = document.querySelector('.table');
};

const handleScroll = () => {
  if (!navigationElement) {
    cacheElements();
  }

  if (!navigationElement) {
    return;
  }

  const scrollY = window.scrollY;
  const isScrollingUp = scrollY < previousScrollY.value;
  const initSpace = navigationElement.clientHeight;
  const rowTop = tableRowElement?.getBoundingClientRect().top || 0;
  const applySmoothTransition = (element: HTMLElement, duration = '0.2s') => {
    element.style.transition = `transform ${duration} ease-out`;
  };
  if (scrollY > initSpace) {
    if (isScrollingUp) {
      navigationElement.style.transform = 'translateY(0)';
      applySmoothTransition(navigationElement);
      if (tableRowElement) {
        if (rowTop <= 0) {
          tableRowElement.style.transform = `translateY(${initSpace}px)`;
          applySmoothTransition(tableRowElement);
        } else if (table) {
          const tableTop = table.getBoundingClientRect().top;
          if (tableTop > 0 && tableTop < initSpace) {
            const spaceNeeded = tableTop - initSpace;
            tableRowElement.style.transform = `translateY(${Math.abs(spaceNeeded)}px)`;
            applySmoothTransition(tableRowElement);
          } else if (tableTop > 0 && tableTop > initSpace) {
            tableRowElement.style.transform = `translateY(0px)`;
            applySmoothTransition(tableRowElement, '0.0s');
          }
        }
      }
    } else {
      navigationElement.style.transform = 'translateY(-100%)';
      applySmoothTransition(navigationElement);
      if (tableRowElement) {
        tableRowElement.style.transform = 'translateY(0)';
        applySmoothTransition(tableRowElement, '0.0s');
      }
    }

    if (!navigationElement.style.position || navigationElement.style.position !== 'sticky') {
      setTimeout(() => {
        if (!navigationElement) {
          cacheElements();
        }

        if (!navigationElement) {
          return;
        }

        navigationElement.style.position = 'sticky';
        applySmoothTransition(navigationElement);
      }, 200);
    }

    if (tableRowElement && rowTop < 0) {
      applySmoothTransition(navigationElement);
      tableRowElement.classList.add('box-shadow-bottom');
    }
  } else {
    if (scrollY <= 1) {
      navigationElement.style.position = 'relative';
      navigationElement.style.transition = 'none';
      navigationElement.style.transform = 'translateY(0)';
      if (tableRowElement) {
        tableRowElement.style.transition = 'none';
        tableRowElement.style.transform = 'translateY(0)';
        tableRowElement.classList.remove('box-shadow-bottom');
      }
    } else {
      if (tableRowElement) {
        tableRowElement.style.transition = 'none';
        tableRowElement.style.transform = `translateY(${scrollY}px)`;
      }
      navigationElement.style.transition = 'none';
      navigationElement.style.transform = `translateY(${scrollY}px)`;
    }
  }

  previousScrollY.value = scrollY;
};

const dropdownExpanded = ref<string[]>([]);

const handleDropdown = (name: string) => {
  if (dropdownExpanded.value.includes(name)) {
    dropdownExpanded.value = dropdownExpanded.value.filter((item) => item !== name);
  } else {
    dropdownExpanded.value = [...dropdownExpanded.value, name];
  }
};

onMounted(() => {
  window.addEventListener('scroll', useDebounceFunc(handleScroll, 25), { passive: true, capture: false, once: false });
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>
