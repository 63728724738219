import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/LoginPage.vue'),
    meta: {
      requiresAuth: false,
      showNavbar: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ForgotPassword.vue'),
    meta: {
      requiresAuth: false,
      showNavbar: false,
    },
  },
  {
    path: '/reset-password/:email/:token',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ResetPassword.vue'),
    meta: {
      requiresAuth: false,
      showNavbar: false,
    },
  },
  {
    path: '/account-setup/:email/:token',
    name: 'AccountSetup',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/AccountSetup.vue'),
    meta: {
      requiresAuth: false,
      showNavbar: false,
    },
  },
  {
    path: '/',
    name: 'LandingDashboard',
    component: () => import(/* webpackChunkName: "user" */ '@/views/LandingDashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/employees',
    name: 'EmployeesView',
    component: () => import(/* webpackChunkName: "user" */ '@/views/EmployeesView.vue'),
  },
  {
    path: '/logs',
    name: 'LogsView',
    component: () => import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/LogsView.vue'),
    meta: {
      roles: ['system-administrator'],
    },
  },
  {
    path: '/logs/:id',
    name: 'LogsDetails',
    component: () =>
      import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/LogsDetails.vue'),
    meta: {
      roles: ['system-administrator'],

      showNavbar: false,
    },
  },

  {
    path: '/scopes',
    name: 'ScopesManagement',
    component: () =>
      import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/ScopesManagement.vue'),
    meta: {
      roles: ['system-administrator'],
    },
  },

  {
    path: '/client-selection',
    name: 'ClientSelection',
    component: () => import(/* webpackChunkName: "user" */ '@/views/ClientSelection.vue'),
    meta: {
      showNavbar: false,
    },
  },
  {
    path: '/clients',
    name: 'ClientsView',
    component: () =>
      import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/ClientsView.vue'),
    meta: {
      roles: ['system-administrator'],
    },
  },
  {
    path: '/clients/:uuid',
    name: 'ClientDetails',
    component: () =>
      import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/ClientDetails.vue'),
    meta: {
      showNavbar: false,
      roles: ['system-administrator'],
    },
  },
  {
    path: '/users-global',
    name: 'UsersViewGlobal',
    component: () =>
      import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/UsersViewGlobal.vue'),
    meta: {
      roles: ['system-administrator'],
    },
  },
  {
    path: '/users-global/:id',
    name: 'UserDetailsGlobal',
    component: () =>
      import(/* webpackChunkName: "system-administrator" */ '@/views/system-administrator/UserDetailsGlobal.vue'),
    meta: {
      showNavbar: false,
      roles: ['system-administrator'],
    },
  },
  {
    path: '/users',
    name: 'UsersView',
    component: () => import(/* webpackChunkName: "tenant-management" */ '@/views/tenant-management/UsersView.vue'),
    meta: {
      scopes: ['tenant-management'],
    },
  },
  {
    path: '/users/:id',
    name: 'UserDetails',
    component: () => import(/* webpackChunkName: "tenant-management" */ '@/views/tenant-management/UserDetails.vue'),
    meta: {
      showNavbar: false,
      scopes: ['tenant-management'],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };
  },
});

export default router;
