import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';

import axios from 'axios';

import Directives from './directives';

import type { UserInfo, ScopeOptions, RoleOptions, PrivilegeOptions } from './types';

import NavigationComponent from '@/components/NavigationComponent.vue';
import {
  InputComponent,
  DropdownComponent,
  TextareaComponent,
  CheckboxComponent,
  ModalComponent,
  ActionsDropdownComponent,
  FileHandlerComponent,
  Format,
} from 'vortec-components-front';
import './registerServiceWorker';
declare global {
  interface Window {
    userData: UserInfo | null;
    defaultErrorMessage: string;
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    window: Window;
    $format: Format;
  }
}

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean;
    scopes?: ScopeOptions[];
    roles?: RoleOptions[];
    privileges?: PrivilegeOptions[];
  }
}

window.userData = null;
window.defaultErrorMessage = 'Something went wrong Please try again later.';
router.beforeEach((to, from, next) => {
  if (window.userData) {
    if (to.meta.requiresAuth === false) {
      next({ name: 'LandingDashboard' });
    } else if (!window.userData.tenant && to.name !== 'ClientSelection') {
      next({ name: 'ClientSelection' });
    } else if (
      (to.meta.scopes && !to.meta.scopes.every((scope) => window.userData?.scopes.includes(scope))) ||
      (to.meta.roles && !to.meta.roles.includes(window.userData.role))
    ) {
      next({ name: 'LandingDashboard' });
    } else {
      next();
    }
  } else {
    if (to.meta.requiresAuth === false) {
      next();
    } else {
      next({ name: 'LoginPage' });
    }
  }
});

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.userData = null;
      if (
        router.currentRoute &&
        router.currentRoute.value &&
        router.currentRoute.value.meta &&
        router.currentRoute.value.meta.requiresAuth
      ) {
        router.push({ name: 'LoginPage' });
      }
    }
    return Promise.reject(error);
  }
);

axios
  .get('/api/session')
  .then((response) => {
    window.userData = response.data;
  })
  .catch(() => {
    window.userData = null;
  })
  .finally(() => {
    Directives(app);

    app.config.globalProperties.window = window;
    app.config.globalProperties.$format = new Format();

    app.component('InputComponent', InputComponent);
    app.component('DropdownComponent', DropdownComponent);
    app.component('TextareaComponent', TextareaComponent);
    app.component('NavigationComponent', NavigationComponent);
    app.component('ModalComponent', ModalComponent);
    app.component('CheckboxComponent', CheckboxComponent);
    app.component('FileHandlerComponent', FileHandlerComponent);
    app.component('ActionsDropdownComponent', ActionsDropdownComponent);

    app.use(router);
    app.mount('#triglav');
  });
